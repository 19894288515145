<template>
  <div class="chessHand" style="padding-bottom: 20px">
    <div v-title data-title="VIP会员每周红包"></div>

    <!-- <van-nav-bar
      title="欢乐棋牌"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->
    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-everyweekvip.png`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img src="../assets/img/pcbb/rules_1.png" v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`" v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>指定会员:</p>
              <span style="color:var(--li-colortext);">VIP1以上会员</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">长期</span>
            </div>
            <div class="title-item">
              <p>参与方式:</p>
              <span style="color:var(--li-colortext);">存款3次以上，流水达到指定要求。</span>
            </div>
            <div class="title-item">
              <p>活动内容:</p>
              <span
                style="color:var(--li-colortext);">会员在上一周（周一0点至周日23点59分59秒）达到会员VIP等级（以周日23:59的VIP等级为准）对应的有效投注要求，即可在每周一的0点后领取每周红包，每周五24点后红包将会过期，请及时登录领取。</span>
            </div>
            <div class="title-item">
              <p>流水要求计算时间:</p>
              <span style="color:var(--li-colortext);margin-left: 10px;"> 周一00:00:00至周日23:59:59</span>
            </div>
            <div class="title-item">
              <p>红包开启时间:</p>
              <span style="color:var(--li-colortext);margin-left: 10px;"> 每周一凌晨00:00:00点开启</span>
            </div>
            <div class="title-item">
              <p>红包失效时间:</p>
              <span style="color:var(--li-colortext);margin-left: 10px;"> 每周一开启，每周五23:59:59失效</span>
            </div>
            <div class="title-item">
              <p>各VIP等级红包金额和领取要求如下:</p>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc);">
                <th
                  style="color: #fff;border-right: 1px solid #e2d2bf;border-top: 1px solid #fff;border-left: 1px solid #fff;font-family: PingFang SC;font-weight: 400;border-top-left-radius: 10px;font-size: 12px;">
                  等级
                </th>
                <th
                  style="color: #fff;border-right: 1px solid #e2d2bf;border-top: 1px solid #fff;font-family: PingFang SC;font-weight: 400;font-size: 12px;padding: 8px 0;">
                  开启时间
                  <p style="color: #9EEBFE;transform: scale(.85);margin-top: 2px;">6月22日</p>
                </th>
                <th
                  style="color: #fff;border-right: 1px solid #e2d2bf;border-top: 1px solid #fff;font-family: PingFang SC;font-weight: 400;font-size: 12px;">
                  流水要求
                </th>
                <th
                  style="color: #fff;border-right: 1px solid #e2d2bf;border-top: 1px solid #fff;font-family: PingFang SC;font-weight: 400;font-size: 12px;">
                  存款次数
                </th>
                <th
                  style="color: #fff;border-right: 1px solid #e2d2bf;border-top: 1px solid #fff;font-family: PingFang SC;font-weight: 400;font-size: 12px;">
                  红包金额
                </th>
                <th
                  style="color: #fff; border-top-right-radius: 10px;font-size: 12px;padding: 8px 0;border-top: 1px solid #fff;border-right: 1px solid #fff;">
                  流水倍数
                  <!-- <p style="color: #9EEBFE;transform: scale(.85);margin-top: 2px;">(流水倍数)</p> -->
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datalist" :key="index">
                <td>{{ item.name }}</td>
                <td>00:00</td>
                <td>{{ item.water_limit }}</td>
                <td>{{ item.dlimit }}</td>
                <td>{{ item.money }}</td>
                <td>{{ item.water }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                VIP1以上会员才能领取。
              </li>
              <li>
                <span>2.</span>
                每周一的0点后领取每周红包，每周五24点后红包将会过期，登录会员账号后系统自动发放，过期登录视为自行放弃。
              </li>
              <li>
                <span>3.</span>
                此活动VIP会员每周可申请一次
              </li>
              <li>
                <span>4.</span>
                在申请此优惠前，请您先完善真实姓名、手机号码、银行卡等个人信息。
              </li>
              <li>
                <span>5.</span>
                若发现有套利客户，对冲或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>6.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>7.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。。
              </li>
              <li>
                <span>8.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>

    <!-- <van-popup v-model="showPicker" round position="bottom">
      <van-picker title="请选择场馆" show-toolbar value-key="name" :columns="datalist.youxi" @confirm="confirm"
        @cancel="cancel" />
    </van-popup> -->
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
export default {
  data () {
    return {
      datalist: [],
      isshow: false,
      timer: null,
      is_token: false,
      aa: { title: 'uni' },
      cueTheme: '',
      showPicker: false,
    }
  },
  onShow () { },

  mounted () {
    if (localStorage.getItem('access_token')) {
      this.is_token = true
    } else {
      this.is_token = false
    }
    this.getdata()
    this.cueTheme = window.localStorage.getItem('cueTheme')
  },

  methods: {
    gotoback () {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },

    getdata () {
      var _this = this
      var data = '?type=zhoubao'
      getdata(data).then((res) => {
        this.datalist = res.message.list
      })
    },
    changeisshow () {
      clearInterval(this.timer)
      this.isshow = true
      this.timer = setTimeout(() => {
        this.isshow = false
      }, 3000)
    },
    // applyWay () {
    //   uni.getEnv((res) => {
    //     if (Object.keys(res)[0] === 'h5') {
    //       Toast('该活动仅在app开发,请下载APP')
    //     } else {
    //       // 申请
    //       this.showPicker = true
    //     }
    //   })
    // },
    confirm (e) {
      uni.postMessage({
        data: {
          urlApply: 'vipActapply',
          actname: 'ChessxuBonus',
          gameid: e.id
        }
      })
      this.showPicker = false
    },
    cancel () {
      this.showPicker = false
    }
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

body {
  background-color: #f7f8fa;
}

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

.chessHand {
  position: relative;
  font-family: PingFang SC;

  .btn-box {
    padding-bottom: 5px;

    >div {
      font-family: PingFang SC;
      font-weight: 400;

      &:nth-child(1) {
        height: 40px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(0deg, #2a6afd, #63c6ff);
        margin: 25px 0px 15px 0px;
      }

      &:nth-child(2) {
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a6afd;
        color: #418beb;
        margin: 15px 0px 15px 0px;
      }
    }
  }

  .rightbox {
    position: fixed;
    top: 150px;
    right: 0;
    z-index: 1;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 5px 5px 5px 0;
    color: #fff;

    .content {
      display: flex;
      align-items: center;

      >div {
        &:nth-child(1) {
          min-width: 54px;
          height: 54px;
          border: 4px solid #fff;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;

          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }

        .primary_color {
          font-size: 11px;
          line-height: 1.2em;
        }
      }
    }
  }
}
</style>
